import React from 'react';
import SearchBar from '../../commons/SearchBar/SearchBar';
import LocationListings from '../LocationListings/LocationListings';

function SearchAndFilter() {
  return (
    <div>
    <header>
    {/* <SearchBar/> */}
    </header>
    <LocationListings/>
  </div>
  );
}

export default SearchAndFilter;
