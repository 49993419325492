import React from "react";

function ErrorPage404() {
    return (
        <div>
            <h1>404 Error page not found</h1>
        </div>
    )
}
export default ErrorPage404;
